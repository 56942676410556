<template>
  <div id="div-main">
    <div id="mainSwap">
      <div id="TokenSwap-Mobile">
        <div id="ChangeColor">
          
        </div>
      </div>
      <div id="img-container">
        <img src="../assets/Image1.png" id="img">
        <img src="../assets/Zamalek_logo_1.png" id="escudo">
      </div>
      <div id="TablesList">
        <div id="TokenSwap">
          <div id="ChangeColor">
            <img id="light" class="ChangeColor" @click="ChangeColor" src="../assets/Moon.png" alt="moon">
          </div>
        </div>

        <div id="YouGet">
          <div id="TokenPrice">
            <h5 class="TokenPrice">Token price: <strong>$4</strong></h5>
          </div>
          <div id="TablesSwap">
            <div id="ListTableGet">
              <div id="TablesGet">
                <div id="TabelaGet">
                  <label for="TokenQnt">Stable qnt:</label>
                    <input
              type="text"
              class="TokenQnt"
              @change="calcTokenValue"
              v-model="stableQnt"
              @keypress="isNumber($event)"
              maxlength="7"
            />
                </div>

                <div id="TabelaGet">
                  <label for="Stables" class="StablesText">Stable:</label>
                  <select
                  v-model="selectedStable"
                  class="TokenSelect"
                  @change="changeStable"
                >
                  <option v-for="stable in stables" v-bind:key="stable">
                    {{ stable }}
                  </option>
                </select>

                </div>
              </div>
              <div id="IDZAMALEK">
                <h5 class="text-Zamalek">{{ this.tokenQnt }} ZAMALEK</h5>
              </div>
              <button type="button" class="Button-Aprrove"  @click="buy" v-if="buyWithShow">Buy</button>
              <div id="Buttons">
                <button class="Button-Aprrove"  @click="stableApprove" v-if="!buyWithShow">Approve</button>
            <button class="Button-Aprrove"  @click="addToMetamask" v-if="!buyWithShow">Add to MetaMask</button>
          </div>
            </div>

          </div>

          <div id="InfoTokens">
            <h5 class="TokensText"><strong>Token Name:</strong> Zamalek Sporting Club</h5>
            <h5 class="TokensText"><strong>Supply:</strong> 50m (50.000.000)</h5>
            <h5 class="TokensText"><strong>Network:</strong> <a  class="TokensText" href="https://polygonscan.com/token/0x7C272aCAC0a8fBa662Fa66A20271023aC86059D5"> Polygon </a></h5>
            <h5 class="TokensText"><strong>Mininum Quantity:</strong> $4</h5>
    
          </div>

          <div id="ButtonRD">
            <a href="https://www.facebook.com/ZSCOfficial/" class="social" target="_blank">
              <img src="../assets/FacebookIcon.png" class="social">
            </a>

            <a href="https://twitter.com/ZSCOfficial" class="social" target="_blank">
              <img src="../assets/TwitterIcon.png" class="social">
            </a>

            <a href="https://www.youtube.com/channel/UC0zCM7UBPakpaVVnZVCntrQ" class="social" target="_blank">
              <img src="../assets/YoutubeIcon.png" class="social">
            </a>

            <a href="https://www.instagram.com/zscofficial/" class="social" target="_blank">
              <img src="../assets/InstagramIcon.svg" class="social">
            </a>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Web3 from "../domain/web3";
import contractTrader from "../abis/trader.json";
import contractToken from "../abis/token.json";
import { ethers } from "ethers";


export default {
  name: "TraderUser",
  inject: [
    "traderContractAddress",
    "contractUsdcAddress",
    "contractUsdtAddress",
    "tokenContractAddress",
  ],
  data() {
    return {
      stableQnt: 0,
      tokenQnt: 0,
      stables: ["USDT", "USDC"],
      selectedStable: "",
      selectedStableId: 0,
      approveShow: true,
      buyWithShow: false,
      black: false,
    };
  },
 

  props: {},


  

  async mounted() {},
  methods: {
     ChangeColor(){
     if(this.black){
      document.body.style = "background: white;"
      document.querySelector("#TablesSwap").style = "background: #F5F5F5; border-color: #DFDFDF;";
      document.querySelector("#TablesGet").style = "background: #F5F5F5; border-color: #DFDFDF;";
      document.querySelector(".TokenQnt").style = "color: #555555;border-color: #DFDFDF; background: #DFDFDF;";
      document.querySelector(".TokenSelect").style =  "color: #555555; border-color: #DFDFDF; background: #DFDFDF;";

      document.querySelectorAll(".Button-Aprrove")[0].style = "color: #FFFFFF;background-color: black;border-color: black;"
      document.querySelectorAll(".Button-Aprrove")[1].style = "color: #FFFFFF;background-color: black;border-color: black;"

      document.querySelectorAll("#TabelaGet")[0].style = "color: black"
      document.querySelectorAll("#TabelaGet")[1].style = "color: black"
      document.querySelector("#InfoTokens").style = "color: black;"
      document.querySelectorAll('.social')[0].childNodes[0].style.filter = 'invert(0%)';
      document.querySelectorAll('.social')[2].childNodes[0].style.filter = 'invert(0%)';
      document.querySelectorAll('.social')[4].childNodes[0].style.filter = 'invert(0%)';
      document.querySelectorAll('.social')[6].childNodes[0].style.filter = 'invert(0%)';

       document.querySelectorAll('.TokensText')[0].style ="color: black";
      document.querySelectorAll('.TokensText')[1].style ="color: black";
      document.querySelectorAll('.TokensText')[2].style ="color: black";
      document.querySelectorAll('.TokensText')[3].style ="color: black";
      document.querySelectorAll('.TokensText')[4].style ="color: black";
      document.querySelector('#light').src = 'https://zamalektoken.com/img/Moon.png';
       document.querySelector('.TokenPrice').style ="color: black;"
       document.querySelector('.text-Zamalek').style ="color: black;"
      console.log(document.querySelector("#ChangeColor").innerHTML);
      this.black = false
     }else{
      document.body.style = "background: black;"
      document.querySelector("#TablesSwap").style = "background: #2E2E2E; border-color: #2E2E2E;";
      document.querySelector("#TablesGet").style = "background: #2E2E2E; border-color: #2E2E2E;";
      document.querySelector(".TokenQnt").style = "color: #FFFFFF; border-color: #555555; background: #555555;";
      document.querySelector(".TokenSelect").style =  "color: #FFFFFF; border-color: #555555; background: #555555;";
      document.querySelectorAll(".Button-Aprrove")[0].style = "color: #000000;background-color: #FFFFFF;border-color:#FFFFFF;"
      document.querySelectorAll(".Button-Aprrove")[1].style = "color: #000000;background-color: #FFFFFF;border-color:#FFFFFF;"

      document.querySelectorAll("#TabelaGet")[0].style = "color: white"
      document.querySelectorAll("#TabelaGet")[1].style = "color: white"
      document.querySelector("#InfoTokens").style = "color: white;"
      document.querySelectorAll('.social')[0].childNodes[0].style.filter = 'invert(100%)';
      document.querySelectorAll('.social')[2].childNodes[0].style.filter = 'invert(100%)';
      document.querySelectorAll('.social')[4].childNodes[0].style.filter = 'invert(100%)';
      document.querySelectorAll('.social')[6].childNodes[0].style.filter = 'invert(100%)';
      document.querySelectorAll('.TokensText')[0].style ="color: white";
      document.querySelectorAll('.TokensText')[1].style ="color: white";
      document.querySelectorAll('.TokensText')[2].style ="color: white";
      document.querySelectorAll('.TokensText')[3].style ="color: white";
      document.querySelectorAll('.TokensText')[4].style ="color: white";
      document.querySelector('.TokenPrice').style ="color: white;"
       document.querySelector('.text-Zamalek').style ="color: white;"
      document.querySelector('#light').src = 'https://zamalektoken.com/img/Sol.png';
      console.log(document.querySelector("#ChangeColor").innerHTML)
      this.black = true

     }
    },

    async calcTokenValue() {
      const web3 = await Web3();
      if (!web3) {
        alert("not found web3");
      } else {
        const web3 = await Web3();

        let contract = new web3.eth.Contract(
          contractTrader,
          this.traderContractAddress
        );

        const staleQntFormated = ethers.utils.parseUnits(
          this.stableQnt.toString(),
          6
        );
        console.log("staleQntFormated", staleQntFormated);

        const tokenValue = await contract.methods
          .calculateTokenQntToBuyWithUsd(staleQntFormated)
          .call(function (err, res) {
            if (err) {
              console.log("An error occured", err);
              return;
            }
            console.log("res value token", res);
            return res;
          });

        console.log("tokenValue", tokenValue);

        const tkvFormated = ethers.utils.formatUnits(tokenValue, 18);

        console.log("tkvFormated", tkvFormated);

        if (tkvFormated.length > 5)
          this.tokenQnt = tkvFormated.slice(0, tkvFormated - 5);
        else this.tokenQnt = tkvFormated;
      }
    },
    async changeStable() {
      if (this.selectedStable === "USDC") {
        this.selectedStableId = 1;
      } else if (this.selectedStable === "USDT") {
        this.selectedStableId = 2;
      }
      this.checkAllowance();
    },
    async checkAllowance() {
      const web3 = await Web3();

      let contract = "";

      if (this.selectedStableId == 1) {
        contract = new web3.eth.Contract(
          contractToken,
          this.contractUsdcAddress
        );
      } else if (this.selectedStableId == 2) {
        contract = new web3.eth.Contract(
          contractToken,
          this.contractUsdtAddress
        );
      }

      var account = (
        await web3.currentProvider.request({ method: "eth_requestAccounts" })
      )[0];

      let allowedValue = await contract.methods
        .allowance(account, this.traderContractAddress)
        .call({ from: account });

      if (allowedValue > 0) {
        this.approveShow = false;
        this.buyWithShow = true;
      } else {
        this.approveShow = true;
        this.buyWithShow = false;
      }
    },
    async buy() {
      const web3 = await Web3();
      if (!web3) {
        alert("not found web3");
      } else {
        const web3 = await Web3();

        let contract = new web3.eth.Contract(
          contractTrader,
          this.traderContractAddress
        );

        var account = (
          await web3.currentProvider.request({ method: "eth_requestAccounts" })
        )[0];

        const staleQntFormated = ethers.utils.parseUnits(
          this.stableQnt.toString(),
          6
        );
        console.log("staleQntFormated", staleQntFormated);

        var gasPrice = await web3.eth.getGasPrice();

        await contract.methods
          .buyWithStable(staleQntFormated, this.selectedStableId)
          .send({ from: account, gasPrice: gasPrice * 2 }, function (err, res) {
            if (err) {
              console.log("An error occured", err);
              return;
            } else {
              console.log("res", res);
            }
            return res;
          });
      }
    },
    async stableApprove() {
      const web3 = await Web3();

      let contractAdddress = "";

      if (this.selectedStableId == 1) {
        contractAdddress = this.contractUsdcAddress;
      } else if (this.selectedStableId == 2) {
        contractAdddress = this.contractUsdtAddress;
      }

      let contractTokenWeb3 = new web3.eth.Contract(
        contractToken,
        contractAdddress
      );

      var account = (
        await web3.currentProvider.request({ method: "eth_requestAccounts" })
      )[0];

      const staleQntFormated = ethers.utils.parseUnits(
        this.stableQnt.toString(),
        6
      );
      
      var gasPrice = await web3.eth.getGasPrice();

      await contractTokenWeb3.methods
        .approve(this.traderContractAddress, staleQntFormated)
        .send({ from: account, gasPrice: gasPrice * 2 })
        .then(() => {
          this.checkAllowance();
        });
    },

    async addToMetamask() {
      const web3 = await Web3();
      await web3.currentProvider.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: this.tokenContractAddress,
            symbol: "ZAMALEK",
            decimals: 18,
            image: "https://i.imgur.com/94YUTLJ.png",
          },
        },
      });
    },
    // async selectMaxValue() {

    //   this.stableQnt = Math.floor(ethers.utils.formatUnits(this.stableQnt, 6));

    //   // const web3 = await Web3();

    //   // let contractStable = "";

    //   // if (this.selectedStableId == 1) {
    //   //   contractStable = new web3.eth.Contract(
    //   //     contractToken,
    //   //     this.contractUsdcAddress
    //   //   );
    //   // } else if (this.selectedStableId == 2) {
    //   //   contractStable = new web3.eth.Contract(
    //   //     contractToken,
    //   //     this.contractUsdtAddress
    //   //   );
    //   // }

    //   // var account = (
    //   //   await web3.currentProvider.request({ method: "eth_requestAccounts" })
    //   // )[0];

    //   // await contractStable.methods
    //   //   .balanceOf(account)
    //   //   .call({ from: account })
    //   //   .then((resp) => {

    //   //   });
    // },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        (charCode !== 46 || charCode !== 100 || charCode !== 190)
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css?family=Noto+Sans");

#div-main {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

#mainSwap {
  display: flex;
  align-items: center;
  width: 90%;
  height: 90%;
  margin: 5%;
}

#img-container {
  width: 50%;
  position: relative;
  
}

#escudo {
  position: absolute;
  right: -91px;
  top: 32%;

}

#img {
  width: 100%;
}


#TokenSwap-Mobile {
  width: 100%;
  display: none;
  justify-content: space-between;
}

.TokenSwap {
  font-family: Noto Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #555555;
}

.ChangeColor {
  cursor: pointer;
}

#YouSend {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 24px;
  flex-direction: column;
}


#YouGet {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 24px;
  flex-direction: column;

}



.PriceText {
  font-family: Noto Sans;
  text-align: center;
  color: #555555;
  font-size: 16px;
  margin-left: 19px;
}


.StableQnt {
  padding: 10px;
  background: #DFDFDF;
  border-radius: 10px;
  border-color: #DFDFDF;
  font-size: 18px;
  font-family: Noto Sans;
  color: #555555;
  border: solid #DFDFDF 5px;
}

.SelectStable {
  font-family: Noto Sans;
  height: 50px;
  color: #555555;
  border-color: #DFDFDF;
  background: #DFDFDF;
  border-radius: 10px;
  padding: 10px;
}

.TokenQnt {
  font-family: Noto Sans;
  height: 50px;
  color: #555555;
  border-color: #DFDFDF;
  background: #DFDFDF;
  border-radius: 10px;
  padding: 10px;
  border: solid #DFDFDF 5px;
}


.TokenSelect {
  font-family: Noto Sans;
  height: 50px;
  color: #555555;
  border-color: #DFDFDF;
  background: #DFDFDF;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
}

#TablesSwap {
  position: relative;
  padding: 30px;
  border: solid 3px #DFDFDF;
  border-radius: 20px;
  display: flex;
  font-size: 16px;
  margin-left: 10px;
  background: #F5F5F5;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
}

#TablesGet {
  padding: 10px;
  border-radius: 20px;
  display: flex;
  font-size: 16px;
  margin-left: 10px;
  background: #F5F5F5;
  width: 100%;
}

#ListTablesGet {
  padding: 30px;
  border: solid 3px #DFDFDF;
  border-radius: 20px;
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-left: 10px;
  background: #F5F5F5;
  width: 100%;
  flex-direction: column;
}




#TabelaSwap {
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
}


#TabelaGet {
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

#TablesList {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  align-items: center;
  margin-left: 8%;
}

.Button-Aprrove {
  color: #FFFFFF;
  background-color: black;
  border: solid 5px black;
  border-radius: 10px;
  padding: 10px;
  width: 55%;
  margin-left: 15px;
}
#Buttons{
  display: flex;
}
#InfoTokens {
  margin-left: 26px;
  display: flex;
  flex-direction: column;
  letter-spacing: 0em;
  align-items: flex-start;
  width: 100%;
}

.ArrowDown {
  position: absolute;
  right: 222px;
  bottom: -38px;
  background-color: white;
  height: 70px;
  width: 70px;
  border: solid 2px #DFDFDF;
  padding: 25px;
  border-radius: 50%;
}


.TokensText {
  font-family: Noto Sans;
  text-align: left;
  font-size: 16.4px;
  line-height: 22px;
  text-decoration: none;
  color: black;
}


#ButtonRD {
  margin-top: 5%;
  width: 50%;
  height: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.social
{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#TokenPrice{
  height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
}
.TokenPrice{
  font-family: Noto Sans;
  text-align: left;
  font-size: 16.4px;
  line-height: 22px;
}
.text-Zamalek{
  font-family: Noto Sans;
  text-align: left;
  font-size: 15.4px;
  line-height: 22px;

}
#IDZAMALEK{
  display: flex;
  justify-content: center;
}



@media screen and (max-width: 900px) {
  #img-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

#img{
  width: 329px;
    height: 149px;
    border-radius: 11%;
}
  #escudo {

    top: 80%;
    height: 79px;
    right: 37%;
  }

  #TablesList{
    margin-left: 0%;
  }

  #TokenSwap {
    display: none;
  }

  #TokenSwap-Mobile {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  #mainSwap {
    flex-direction: column;
  }

}


@media screen and (max-width: 700px) {
  .StablesText{
    display: none;
  }
  #escudo {

    top: 80%;
    height: 79px;
    right: 37%;
  }

  #TokenSwap {
    display: none;
  }

  #TablesSwap {
    flex-direction: column;
    width: 90%;
    height: 90%;
  }

  #TablesGet {
    flex-direction: column;
  }

  .ArrowDown {
    right: 171px;
  }

  #TokenSwap-Mobile {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  #mainSwap {
    flex-direction: column;
  }

}
</style>