import Vue from "vue";
import App from "./App.vue";

import router from "./router";

Vue.config.productionTip = false;

new Vue({
  provide: {
    traderContractAddress: "0xa1A7552CfAEf4A3d0d619610f1fD4C389559faB0",
    contractUsdcAddress: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
    contractUsdtAddress: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    tokenContractAddress: "0x7C272aCAC0a8fBa662Fa66A20271023aC86059D5",
  },
  router,
  render: (h) => h(App),
}).$mount("#app");
