<template>
  <div class="row">
    <h4 class="d-flex justify-content-between align-items-center mb-3">
      Token - Admin
    </h4>
    <div class="row">
      <div class="col-lg-2 mx-auto">
        <h5>Roles</h5>

        <div class="mb-2">
          <label for="exampleInputEmail1" class="form-label">Ação</label>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              v-model="rolesAcaoId"
              value="1"
            />
            <label class="form-check-label" for="flexRadioDefault1">
              Adicionar
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              v-model="rolesAcaoId"
              value="2"
              id="flexRadioDefault2"
            />
            <label class="form-check-label" for="flexRadioDefault2">
              Remover
            </label>
          </div>
        </div>
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">Endereço</label>
          <input
            type="text"
            class="form-control"
            id="roleAddress"
            aria-describedby="emailHelp"
            v-model="selectedAddressRole"
          />
        </div>
        <div class="row mb-3">
          <label for="exampleInputEmail1" class="form-label">Role</label>
          <div class="col-md-6 align-items-center">
            <select
              v-model="selectedRole"
              style="width: 10%"
              class="w-100 form-select"
            >
              <option v-for="role in roles" v-bind:key="role">
                {{ role }}
              </option>
            </select>
          </div>
        </div>
        <button type="submit" class="btn btn-primary" @click="changeRole">
          Setar
        </button>
      </div>
      <div class="col-lg-2 mx-auto">
        <h5>Criar Tokens Mint</h5>

        <div class="mb-2">
          <label for="exampleInputEmail1" class="form-label">Quantidade</label>
          <input
            type="number"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            v-model="mintQnt"
          />
        </div>
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label"
            >Endereço para receber</label
          >
          <input
            type="text"
            class="form-control"
            aria-describedby="emailHelp"
            v-model="addressMintReceiver"
          />
        </div>
        <button type="submit" class="btn btn-primary" @click="mint">
          Criar Tokens
        </button>
      </div>
      <div class="col-lg-2 mx-auto">
        <h5>Withdraw Emergência</h5>
        <div class="mb-2">
          {{ eTokenQuantity }}
        </div>

        <div class="mb-2">
          <button type="submit" class="btn btn-primary" @click="withdrawEToken">
            Withdraw
          </button>
        </div>
      </div>
      <div class="col-lg-2 mx-auto">
        <h5>Modificar Token Emergência</h5>
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label"
            >Endereço do token</label
          >
          <input
            type="text"
            class="form-control"
            aria-describedby="emailHelp"
            v-model="eTokenAddress"
          />
        </div>
        <button type="submit" class="btn btn-primary" @click="changeEToken">
          Modificar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Web3 from "../domain/web3";
import contractToken from "../abis/token.json";
import { ethers } from "ethers";
const keccak256 = require("keccak256");

export default {
  name: "TokenAdmin",
  inject: ["tokenContractAddress"],
  data() {
    return {
      mintQnt: 0,
      totalInContract: 0,
      rolesAcaoId: 0,
      roles: ["Admin"],
      selectedRole: "",
      selectedAddressRole: "",
      eTokenAddress: "",
      eTokenQuantity: 0,
      addressMintReceiver: "",
    };
  },
  props: {},

  async mounted() {
    await this.getETokenQuantity();
  },
  methods: {
    async mint() {
      const web3 = await Web3();
      if (!web3) {
        alert("not found web3");
      } else {
        const web3 = await Web3();

        let contract = new web3.eth.Contract(
          contractToken,
          this.tokenContractAddress
        );

        var account = (
          await web3.currentProvider.request({ method: "eth_requestAccounts" })
        )[0];

        const mintQntFormated = ethers.utils.parseUnits(
          this.mintQnt.toString(),
          18
        );
        await contract.methods
          .mint(this.addressMintReceiver, mintQntFormated)
          .send({ from: account }, function (err, res) {
            if (err) {
              console.log("An error occured", err);
              return;
            } else {
              console.log("res", res);
            }
            return res;
          });
      }
    },
    async changeRole() {
      const web3 = await Web3();
      if (!web3) {
        alert("not found web3");
      } else {
        const web3 = await Web3();

        let contract = new web3.eth.Contract(
          contractToken,
          this.tokenContractAddress
        );

        var account = (
          await web3.currentProvider.request({ method: "eth_requestAccounts" })
        )[0];

        let decodedRole = "";
        if (this.selectedRole === "Admin") decodedRole = "0x00";
        else if (this.selectedRole == "Minter")
          decodedRole = keccak256("MINTER_ROLE");
        else if (this.selectedRole === "Withdraw Emergency")
          decodedRole = keccak256("EWITHDRAW_ROLE");
        if (this.rolesAcaoId == 1) {
          await contract.methods
            .grantRole(decodedRole, this.selectedAddressRole)
            .send({ from: account }, function (err, res) {
              if (err) {
                console.log("An error occured", err);
                return;
              } else {
                console.log("res", res);
              }
              return res;
            });
        } else if (this.rolesAcaoId == 2) {
          await contract.methods
            .revokeRole(decodedRole, this.selectedAddressRole)
            .send({ from: account }, function (err, res) {
              if (err) {
                console.log("An error occured", err);
                return;
              } else {
                console.log("res", res);
              }
              return res;
            });
        }
      }
    },
    async changeEToken() {
      const web3 = await Web3();
      if (!web3) {
        alert("not found web3");
      } else {
        const web3 = await Web3();

        let contract = new web3.eth.Contract(
          contractToken,
          this.tokenContractAddress
        );

        var account = (
          await web3.currentProvider.request({ method: "eth_requestAccounts" })
        )[0];

        await contract.methods
          .modifiyEToken(this.eTokenAddress)
          .send({ from: account }, function (err, res) {
            if (err) {
              console.log("An error occured", err);
              return;
            } else {
              console.log("res", res);
            }
            return res;
          });
      }
    },
    async getETokenQuantity() {
      const web3 = await Web3();
      if (!web3) {
        alert("not found web3");
      } else {
        const web3 = await Web3();

        let contract = new web3.eth.Contract(
          contractToken,
          this.tokenContractAddress
        );

        var account = (
          await web3.currentProvider.request({ method: "eth_requestAccounts" })
        )[0];

        const eTokenValue = await contract.methods
          .getEtokenBalance()
          .call({ from: account }, function (err, res) {
            if (err) {
              console.log("An error occured", err);
              return;
            } else {
              console.log("res", res);
            }
            return res;
          });
        this.eTokenQuantity = eTokenValue / 10 ** 18;
      }
    },
    async withdrawEToken() {
      const web3 = await Web3();
      if (!web3) {
        alert("not found web3");
      } else {
        const web3 = await Web3();

        let contract = new web3.eth.Contract(
          contractToken,
          this.tokenContractAddress
        );

        var account = (
          await web3.currentProvider.request({ method: "eth_requestAccounts" })
        )[0];

        await contract.methods
          .withDrawEtoken()
          .send({ from: account }, function (err, res) {
            if (err) {
              console.log("An error occured", err);
              return;
            } else {
              console.log("res", res);
            }
            return res;
          });
      }
    },
  },
};
</script>
