<template>
  <TokenAdmin />
</template>

<script>
// @ is an alias to /src
import TokenAdmin from "@/components/TokenAdminComponent.vue";

export default {
  name: "TokenAdminComponent",
  components: {
    TokenAdmin,
  },
};
</script>
