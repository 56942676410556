<template>
  <User />
</template>

<script>
// @ is an alias to /src
import User from "@/components/TraderUserComponent.vue";

export default {
  name: "UserComponent",
  components: {
    User,
  },
};
</script>
