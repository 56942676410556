<template>
  <TraderAdmin />
</template>

<script>
// @ is an alias to /src
import TraderAdmin from "@/components/TraderAdminComponent.vue";

export default {
  name: "TradeAdminComponent",
  components: {
    TraderAdmin,
  },
};
</script>
