import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import User from "../views/TokenUser.vue";
import TokenAdmin from "../views/TokenAdmin.vue";
import TraderAdmin from "../views/TraderAdmin.vue";
import Contratos from "../views/Contratos.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/User",
    name: "User",
    component: User,
  },
  {
    path: "/TokenAdmin",
    name: "TokenAdmin",
    component: TokenAdmin,
  },
  {
    path: "/TraderAdmin",
    name: "TraderAdmin",
    component: TraderAdmin,
  },
  {
    path: "/Contratos",
    name: "Contratos",
    component: Contratos,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
