<template>
  <div class="col py-3">
    <div class="container py-4">
      <div class="p-1 bg-light rounded-3">
        <div class="container-fluid py-1">
          <dl class="row">
            <dt class="col-sm-3">Trader</dt>
            <dd class="col-sm-9">
              {{ traderContractAddress }}
            </dd>

            <dt class="col-sm-3">Token</dt>
            <dd class="col-sm-9">
              {{ tokenContractAddress }}
            </dd>

            <dt class="col-sm-3">USDC</dt>
            <dd class="col-sm-9">
              {{ contractUsdcAddress }}
            </dd>
            <dt class="col-sm-3">USDT</dt>
            <dd class="col-sm-9">
              {{ contractUsdtAddress }}
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "ContractsView",
  inject: [
    "traderContractAddress",
    "contractUsdcAddress",
    "contractUsdtAddress",
    "tokenContractAddress",
  ],
};
</script>
